import React from 'react';
import './hrs.css';
const Footer = () => {
  return (
    <footer>
     
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br><br></br>
    <hr className='hrs'></hr>
      <p>www.zorbyapp.com &copy; 2024</p>
    </footer>
  );
}

export default Footer;